@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;600;700;800;900&family=IBM+Plex+Sans:wght@100;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;600;700;800;900&family=IBM+Plex+Sans:wght@100;300;400;500;600;700;800&family=Public+Sans:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;600;700;800;900&family=IBM+Plex+Sans:wght@100;300;400;500;600;700&family=Playfair+Display:wght@500&family=Public+Sans:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --white: #ffffff;
  --brown: #8b4513;
  --red: #cd1e0e;
  --red-error: #fd3636;
  --red-hover: #e42110;
  --red-medium: #cb1417;
  --red-light: #ffd2d2;
  --pink: #dd3849;
  --pink-hover: #fc3050;
  --orange: #ff5722;
  --blue-dark: #0e2b5c;
  --blue-navy: #0052cc;
  --blue-btn: #006097;
  --blue-new: #0052cc;
  --blue-new-hover: #0052ccd9;
  --blue-clear: #e6f7ff;
  --blue-light: #3c85bd;
  --blue-nav-link: #0a66c2;
  --gray-light: #eee;
  --gray-medium: #ccc;
  --yellow-medium: #d6ff49;
  --green-medium: #43d13f;
  --green-medium-hover: #48de43;
  --dark-green: darkGreen;
  --text-dark: #233244;
  --text-light: #51668a;
  --text-dark-primary-color: #3a485a;
  --text-black: #333333;
  --text-gray-light: rgba(255, 255, 255, 0.6);
  --text-gray-dark: #8b969e;

  --font-color-light: rgba(0, 0, 0, 0.6);
  --font-color-medium: rgba(0, 0, 0, 0.75);
  --font-color-strong: rgba(0, 0, 0, 9);

  --bckg-icon-hover: rgba(0, 0, 0, 0.08);
  --icon-color: #9ea2a8;
  --big-icon-color: #c3d8ee;
  --border-light: #e0e4e9;
  --input-gray-light: #f1f0ed;
  --background-gray: #f0f0f0;

  --bckg-gray-gradient: linear-gradient(175deg, #ececec 0, #d2d2d2 100%);
  --bckg-inactive: #d6d6d6;

  --green-hover: #34d058;
  --green-gradient: linear-gradient(180deg, #34d058 0, #28a745 90%);

  --background-light: #f3f2ef;
  --background-lighter: #f9f9f9;
  --background-dark: linear-gradient(320deg, #070e18 0, #2a3b56 100%);
  --background-header: linear-gradient(
    to right,
    #1e2a3a 0,
    #273146 29%,
    #1e3750 64%,
    #152e42 100%
  );

  --box-shadow-default: 0px 5px 83px 0px rgb(40 40 40 / 12%);
  --box-shadow-box: 0 0 0 1px rgb(0 0 0 / 1%);

  --border-default: #ddd;
  --border-accordion: #d9d9d9;
  --blue-border: #91d5ff;
  --radius-default-card: 10px;

  --success-green-darker: #81b91e;
  --success-green-lighter: #ebf4de;
  --pending-yellow-darker: #f8ac59;
  --pending-yellow-lighter: #fff8e0;

  --border-app: #0000001f;

  --placeholder-icon: #808080;
  --dialog-bckg: #283447;
  --dialog-strong: #b8c7e0;
  --dialog-separator: rgba(184, 199, 224, 0.31);
  --dialog-box-shadow: 0px 5px 15px 5px rgb(0 0 0 / 50%);
  --dialog-btn-bckg-confirm: #4c9aff;
  --dialog-btn-bckg-confirm-hover: #b3d4ff;
  --dialog-btn-bckg-cancel: transparent;
  --dialog-btn-bckg-cancel-hover: #313d52;
  --dialog-btn-color-cancel: #9fb0cc;
  --dialog-btn-padding: 0.5rem 1rem 0.5rem 1rem;
  --dialog-btn-radius: 3px;
  --dialog-btn-font: 16px;
  --dialog-btn-bckg-transition: background-color 0.2s ease-in 0.2s;
  --color-transition: color 0.2s ease-in 0.2s;
  --btn-filter-transition: filter 0.3s ease;

  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;

  --font-public-sans: 'Public Sans', sans-serif;
  --font-heebo: 'Heebo', sans-serif;

  --font-family-special: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;

  --font-family-play: 'Playfair Display', serif;

  --font-family-registration: -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background-color: var(--background-light);
}

.app {
  background-color: #f3f2ef;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family-special);
}

.app__body {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 60%;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
    padding: 0 1rem 0 1rem;
  }
}

.default__padding {
  padding: 1rem;
}

/* **** ACCORDION **** */
.default__accordion {
  background: var(--bckg-gray-gradient);
  font-size: 16px;
}

.default-input {
  font-size: 14px;
  font-family: var(--font-family-special);
  padding: 6px;
  color: var(--font-color-medium);
  background-color: var(--background-light);
  border: 1px solid var(--dialog-separator);
  outline: none;
  box-shadow: var(--box-shadow-default);
  transition: border-color 0.3s ease;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    &:not(:disabled) {
      border-color: var(--dialog-btn-bckg-confirm);
    }
  }

  &:focus {
    &:not(:disabled) {
      border-color: var(--dialog-btn-bckg-confirm);
    }
  }

  &::placeholder {
    color: rgb(189, 189, 189);
  }
}

.delete-modal-title {
  display: flex;
  align-items: center;
  gap: 8px;

  i {
    font-size: 32px;
    color: var(--pending-yellow-darker);
  }
}

.fa-trash-o {
  font-size: 18px;
}

.flag-circle {
  width: 20px !important;
  height: 20px;
  margin-right: 0.3rem;
  border-radius: 50%;
  border: solid 1px var(--big-icon-color);
}

.default__heading__accordion {
  display: unset;

  span {
    position: relative;
    cursor: pointer;
    left: 4px;
    top: 1px;

    i {
      font-size: 20px;

      &:hover {
        color: var(--font-color-medium);
      }
    }
  }
}

.ant-alert {
  border-radius: 8px;
  box-shadow: -10px 10px 30px -10px rgb(200, 200, 200, 64%);
}

.ant-tooltip-inner {
  border-radius: 4px;
  padding: 0.5rem;
  text-align: center;
  max-width: 250px;

  .default__tooltip {
    font-family: var(--font-family-special);

    h4 {
      color: var(--dialog-strong);
      font-weight: 600;
      font-size: 16px;
    }

    div {
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.ant-popover {
  width: 250px;
}

.ant-skeleton-element .ant-skeleton-image {
  min-width: 100%;
  min-height: 450px;
}

.ant-skeleton-element {
  width: 100% !important;
}

quill-view-html > div > div.ql-editor {
  font-family: var(--font-family-sans-serif);
  font-size: 14px;
}

nz-modal-container {
  z-index: 999 !important;
}

.cdk-overlay-backdrop,
.ant-modal-mask {
  z-index: 999 !important;
}

.pac-container-customized {
  top: 40px !important;
  left: 0 !important;
  z-index: 999999 !important;
  display: inherit;
}
